html,
body,
div,
img,
nav,
p,
ul,
li,
figcaption,
figure,
button {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  appearance: none;
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: unset;
  }
}

html,
body {
  width: 100%;
  height: 100%;
}

img {
  display: block;
  user-select: none;
}

a {
  text-decoration: none;
}

button {
  appearance: none;
  border: none;
  background: none;
}

.pointer {
  cursor: pointer;
}

.nowrap {
  white-space: nowrap;
}

.full-screen {
  width: 100%;
  height: 100vh;
}

.full-parent {
  width: 100%;
  height: 100%;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-column-reverse {
  display: flex;
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.mini-scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-button {
    background-color: transparent;
  }
}

.relative {
  position: relative;
}
