@import "./base.scss";
@import "./variables.scss";

#root {
  height: 100%;
  min-height: 100%;
}

.splash-screen {
  height: 100%;
  background: linear-gradient(-45deg, #33eccb, #6f82f2);
  overflow: hidden;

  .logo {
    width: 80vw;
  }

  .start-game {
    $hr: 54px;

    font-size: 20px;
    font-weight: bold;
    letter-spacing: 2px;
    color: white;
    background: linear-gradient(45deg, #ff5e53, #746aff);
    line-height: $hr;
    padding: 0 32px;
    border-radius: 16px;
    box-shadow: 6px 6px 6px rgba($color: #000000, $alpha: 0.1);
    transition: transform 0.2s, box-shadow 0.2s;

    &:active {
      transform: scale(0.98) translateY(6px);
      box-shadow: 6px 6px 6px rgba($color: #000000, $alpha: 0);
    }
  }
}
