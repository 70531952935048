@import "../../styles/variables.scss";

.yard {
  .block-container {
    $blockSize: 100vw / 50;
    $blockBorder: solid #eaeaea 1px;
    background-color: #a5b003;

    width: $blockSize * 50;
    flex-wrap: wrap;
    box-sizing: content-box;

    .block-item {
      width: $blockSize;
      height: $blockSize;
      text-align: center;
      line-height: $blockSize;

      &.filled {
        background-color: #3b4f11;
      }

      &.head {
        border: solid black 1px;
        background-color: #88ad39;
        transform: scale(1.06);
      }

      &.type-edge {
        background-color: black;
      }

      &.type-food {
        border-radius: 50%;
        background-color: #ebd27f;
      }
    }
  }
}
